import { useRoutes } from 'react-router-dom';
import Loadable from 'components/common/Loadable';
import { lazy } from 'react';

const SecretEvent = Loadable(lazy(() => import('pages/SecretEvent')));
const UnlimitedCouponEvent = Loadable(lazy(() => import('pages/UnlimitedCouponEvent')));
const ShinhanEvent = Loadable(lazy(() => import('pages/ShinHanEvent')));
const SearchEvent = Loadable(lazy(() => import('pages/SearchEvent')));
const TossQuizEventHint = Loadable(lazy(() => import('pages/TossQuizEventHint')));
const TossQuizEvent = Loadable(lazy(() => import('pages/TossQuizEvent')));
const InstagramEvent = Loadable(lazy(() => import('pages/InstagramEvent')));

export default function Routers() {
  return useRoutes([
    {
      path: '/',
      children: [
        {
          path: 'event',
          children: [
            {
              path: 'secretEvent',
              element: <SecretEvent />
            },
            {
              path: 'unlimitedCouponEvent',
              element: <UnlimitedCouponEvent />
            },
            {
              path: 'shinhanEvent',
              element: <ShinhanEvent />
            },
            {
              path: 'searchEvent',
              element: <SearchEvent />
            },
            {
              path: 'tossQuizEventHint',
              element: <TossQuizEventHint />
            },
            {
              path: 'tossQuizEvent',
              element: <TossQuizEvent />
            },
            {
              path: 'instagramEvent',
              element: <InstagramEvent />
            }
          ]
        }
      ]
    }
  ]);
}
